import React, { useEffect, useState } from "react";
import moment from "moment";
import arrowLeft from "../../../assets/icons/arrow-left.svg";
import arrowRight from "../../../assets/icons/arrow-right.svg";
import FilterItem from "./filter_item";
import TabItem from "./tab_item";
import MonthView from "./month_view";
import WeekView from "./week_view";
import DayView from "./day_view";
import ScheduleView from "./schedule_view";
import { Tab } from "@headlessui/react";
import Header from "../header/Header";
import BreadCrumb from "../global/breadcrumb/Breadcrumb";
import Footer from "../global/footer/Footer";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { homeAPI_apirequest } from "../../../API/api";
import { endpoint } from "../../../API/config";
import { loginDetails } from "../../../redux/features/login/LoginSlice";
import { getDaysInWeek } from "./utils";
import {
  changeSelectedDate,
  setCalendarView,
} from "../../../redux/features/calendar/calendarSlice";

function Calendar() {
  const selectedDate = useSelector((state) => state.calendar.selectedDate);
  const [month, setMonth] = useState(selectedDate.format("MMMM"));
  const [year, setYear] = useState(selectedDate.format("YYYY"));
  const [apiData, setApiData] = useState();
  const [events, setEvents] = useState([]);
  const LoginDetails = useSelector(loginDetails);
  const [week, setWeek] = useState(getDaysInWeek(moment().startOf("day")));

  const [filters, setFilters] = useState({
    All: {
      state: true,
      val: "All",
    },
    Tests: {
      state: true,
      val: "Test",
    },
    Events: {
      state: true,
      val: "Event",
    },
    "Mentor Visits": {
      state: true,
      val: "Mentor Visit",
    },
    Classes: {
      state: true,
      val: "Class",
    },
  });

  const courseId = useSelector((state) => state.login.courseId);
  const variantId = useSelector((state) => state.login.variantId);
  const views = ["Month", "Week", "Day", "Schedule"];
  const dispatch = useDispatch();
  const selectedView = useSelector((state) => state.calendar.selectedView);

  function setSelectedView(view) {
    dispatch(setCalendarView(view));
  }

  function setSelectedDate(date) {
    dispatch(changeSelectedDate(date));
  }

  const changeMonth = (num) => {
    let date = moment(selectedDate).add(num, "month");
    setSelectedDate(date);
    setMonth(date.format("MMMM"));
    setYear(date.format("YYYY"));
  };

  const changeDay = (day) => {
    setSelectedDate(day);
  };

  const addMonth = () => {
    changeMonth(1);
  };

  const subtractMonth = () => {
    changeMonth(-1);
  };

  // Events api call

  const fetchFilterEvents = (courseId) => {
    return homeAPI_apirequest({
      url: `${endpoint.calendar_filters.addr}`,
      method: "post",
      headers: {
        "access-token": LoginDetails.token,
      },
      data: {
        courseId: courseId.toString(),
        variantId: variantId.toString(),
      },
    });
  };

  useQuery(
    [endpoint.calendar_filters.key, courseId],
    () => fetchFilterEvents(courseId),
    {
      enabled: !!courseId,
      select: (data) => data.data.data,
      retry: false,
      onSuccess: (data) => {
        setApiData(data);
        let temp = [];
        let keys = Object.keys(data);
        keys.forEach((key) => {
          let new_data = data[key] ? data[key] : [];
          temp.push(...new_data);
        });
        setEvents([...temp]);
      },
    }
  );

  useEffect(() => {
    if (selectedView === 0) {
      setMonth(moment(selectedDate).format("MMMM"));
      setYear(moment(selectedDate).format("YYYY"));
    }
  }, [selectedView]);

  const otherFilterAreTrue = (filterName) => {
    const temp = [];
    Object.entries(filters).forEach(([key, value]) => {
      if (key !== filterName && key !== "All") {
        temp.push(value);
      }
    });
    return temp.every((val) => val.state === true);
  };

  const handleFilters = (filterName) => {
    if (filterName === "All") {
      if (filters.All.state) {
        filters.All.state = false;
        filters.Tests.state = false;
        filters.Events.state = false;
        filters["Mentor Visits"].state = false;
        filters.Classes.state = false;
        setEvents([]);
      } else {
        filters.All.state = true;
        filters.Tests.state = true;
        filters.Events.state = true;
        filters["Mentor Visits"].state = true;
        filters.Classes.state = true;
        let temp = [];
        let keys = Object.keys(apiData);
        keys.forEach((key) => {
          temp = [...temp, ...apiData[key]];
        });
        setEvents([...temp]);
      }
      setFilters({ ...filters });
    } else {
      if (filters[filterName].state) {
        filters[filterName].state = false;
        filters.All.state = false;
        let filteredEvents = [];
        for (const key in filters) {
          if (key !== filterName && key !== "All" && filters[key].state)
            filteredEvents.push(...apiData[key.replace(/\s/g, "")]);
        }
        // keys.forEach(key =>
        setEvents([...filteredEvents]);
      } else {
        if (otherFilterAreTrue(filterName)) {
          filters.All.state = true;
        }
        filters[filterName].state = true;
        setEvents([...events, ...apiData[filterName.replace(/\s/g, "")]]);
      }
      setFilters({ ...filters });
    }
  };

  return (
    <Tab.Group selectedIndex={selectedView} onChange={setSelectedView}>
      <div className="font-ibm">
        <Header />
        <div className="mt-8">
          <BreadCrumb />
        </div>
        <div className="xl:max-w-[1440px] w-full grid gap-5 md:grid-cols-[250px,1fr] mx-auto mt-8 px-5 md:px-8 lg:px-10">
          {/* Left section */}
          <div className="w-full grow ">
            <p className="text-2xl font-medium  py-[13px]  mb-9 ">Calendar</p>
            <div className="shadow-outer px-4 py-6 rounded-2xl ">
              <p className=" ml-2 mb-6 text-[18px] font-medium  ">Filters</p>
              {Object.entries(filters).map(([key, val]) => (
                <FilterItem
                  key={key}
                  filterName={key}
                  condition={val.state}
                  func={handleFilters}
                />
              ))}
            </div>
          </div>

          {/* Right section */}
          <div className=" text-[14px]">
            {/* Month change controls */}
            <div className="mb-9 flex items-center justify-between gap-3">
              <div className="flex items-center">
                <div
                  onClick={subtractMonth}
                  className="cursor-pointer w-12 h-12 bg-white rounded-full shadow-outer flex items-center justify-center"
                >
                  <img src={arrowLeft} alt="left-arrow" loading="lazy" />
                </div>
                <p className="text-2xl font-medium  w-48 whitespace-nowrap text-center ml-2 mr-2 ">
                  {month}&nbsp;{year}
                </p>
                <div
                  onClick={addMonth}
                  className="cursor-pointer w-12 h-12 bg-white rounded-full shadow-outer flex items-center justify-center "
                >
                  <img src={arrowRight} alt="arrow-right" loading="lazy" />
                </div>
              </div>

              {/* Tab selector */}
              <Tab.List className="bg-secondary-200 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 rounded-2xl max-w-fit">
                {views.map((view) => (
                  <TabItem key={view} tab_name={view} />
                ))}
              </Tab.List>
            </div>

            {/* days in grid */}
            <Tab.Panels>
              <Tab.Panel className=" shadow-outer py-[2.4294%] px-[3.4009%] rounded-2xl">
                <MonthView
                  events={events}
                  setMonth={setMonth}
                  setYear={setYear}
                  selectedDate={selectedDate}
                  setSelectedView={setSelectedView}
                  changeDay={changeDay}
                />
              </Tab.Panel>
              <Tab.Panel className=" shadow-outer py-[2.4294%] px-[3.4009%] rounded-2xl">
                <WeekView
                  events={events}
                  setMonth={setMonth}
                  setYear={setYear}
                  week={week}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  setWeek={setWeek}
                />
              </Tab.Panel>
              <Tab.Panel className=" shadow-outer py-[2.4294%] px-[3.4009%] rounded-2xl ">
                <DayView
                  events={events}
                  date={selectedDate}
                  setSelectedDate={setSelectedDate}
                  setMonth={setMonth}
                  setYear={setYear}
                />
              </Tab.Panel>
              <Tab.Panel className=" shadow-outer py-[2.4294%] px-[3.4009%] rounded-2xl ">
                <ScheduleView events={events} month={selectedDate} />
              </Tab.Panel>
            </Tab.Panels>
          </div>
        </div>
        <Footer />
      </div>
    </Tab.Group>
  );
}

export default Calendar;
