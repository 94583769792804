import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  selectSelectedSubheaderTabName,
  selectSecondSubheaderData,
  clickOnSecondHeaderTab,
} from "../../../redux/features/header/headerSlice";

function SelectedSubHeader({ selectedDropdownOption, isFromStudentProfile }) {
  const dispatch = useDispatch();
  const headerTabId = useSelector((state) => state.header.headerId);
  const headerName = useSelector((state) => state.header.currentHeaderTab);
  const learningPathName = useSelector(
    (state) => state.header.currentDropdownOption
  );
  const learningPathId = useSelector((state) => state.header.id);
  const selectedSubheaderTabName = useSelector(selectSelectedSubheaderTabName);
  const secondSubheaderData = useSelector(selectSecondSubheaderData);
  const history = useHistory();

  const handleClick = (option) => {
    localStorage.setItem("breadcrumb1", option.name);
    dispatch(clickOnSecondHeaderTab(option.name));
    if (option.isHeader === "1") {
      const module = option.type.toLowerCase();
      if (module === "hyperlink") {
        window.open(option.url);
      }
      if (isFromStudentProfile) {
        let studentImsPin = localStorage.getItem("studentImsPin");
        let courseName = localStorage.getItem("courseName");
        let variantId = localStorage.getItem("variantId");
        let courseId = localStorage.getItem("courseId");
        history.push(
          `/studentPage/${selectedSubheaderTabName}/${option.name}/?parentId=${headerTabId}&id=${option.learningPathId}&studentImsPin=${studentImsPin}&variantId=${variantId}&courseId=${courseId}&courseName=${courseName}&learningPathId=${learningPathId}&learningPathName=${learningPathName}`
        );
      } else if (
        module === "prepare" ||
        module === "test" ||
        module === "performance"
      ) {
        history.push(
          `/${module}-${headerName}/${selectedSubheaderTabName}/${option.name}/?parentId=${headerTabId}&id=${option.learningPathId}&learningPathId=${learningPathId}&learningPathName=${learningPathName}`
        );
      }
    }
  };

  return (
    <div className="flex flex-wrap shadow-outer items-center">
      <div className="px-6 py-5 ml-14 mr-4">{selectedSubheaderTabName} </div>
      <span className="mr-4">|</span>
      {secondSubheaderData.length > 0 &&
        secondSubheaderData.map((data, ind) => (
          <div
            onClick={() => handleClick(data)}
            key={ind}
            className={`px-6 py-5 font-ibm font-normal text-lg text-secondary-600 cursor-pointer mr-4 ${
              selectedDropdownOption?.toLowerCase() === data.name?.toLowerCase()
                ? "border-b-2 border-primary-200 font-semibold"
                : ""
            }`}
          >
            {data.name}
          </div>
        ))}
    </div>
  );
}

SelectedSubHeader.propTypes = {
  secondSubheaderData: PropTypes.array,
  selectedDropdownOption: PropTypes.string,
  currentHeaderTab: PropTypes.string,
  isFromStudentProfile: PropTypes.bool,
};
export default SelectedSubHeader;
