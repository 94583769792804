import React from "react";
import PropTypes from "prop-types";
import Mail from "../../../../assets/icons/mail.svg";
import Call from "../../../../assets/icons/call.svg";
const ProfileSection = ({ userData }) => {
  return (
    <div className="grid grid-rows-1 grid-flow-col gap-4 p-5 shadow-outer rounded-2xl my-10 font-ibm">
      <img
        src={userData.profileImage}
        alt="profileImage"
        className="h-20 object-cover rounded-t-2xl"
        loading="lazy"
      />
      <div className="grid-rows-3 col-span-12 -ml-4">
        <p className="text-2xl font-medium font-ibm mt-4">
          {userData.firstName + " " + userData.lastName}
        </p>
        <p className="flex font-normal text-base space-x-2 mt-4">
          <img src={Mail} loading="lazy" />
          <span>{userData.emailId}</span>
        </p>
        <p className="flex font-normal text-base space-x-2 mt-4">
          <img src={Call} loading="lazy" />
          <span>{userData.mobileNumber}</span>
        </p>
      </div>
    </div>
  );
};

ProfileSection.propTypes = {
  userData: PropTypes.Object,
};
export default ProfileSection;
